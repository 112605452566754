<template>
  <div class="row">
    <div class="col-12">
      <button type="submit" class="btn btn-primary float-right" @click="upload = true">Cadastrar documentos</button>
    </div>
    <empresa-unidade-documentos-upload :empresaUnidadeId="empresaId" v-if="upload" @fechar="fecharModal" />
    <empresa-unidade-alterar-documento-upload :documento="documento" v-if="documento != null" @fechar="fecharModal" />
    <div class="col-12">
      <MyTable
        :searchable="true"
        :data="documentos"
        :columns="columns"
        :itemsPerPage="10">
        <template v-slot:row="{ row }">
          <td>
            {{row.nome_arquivo}}
          </td>
          <td>
            {{row.created_at | maskdateptbr}}
          </td>
          <td @click="obterDocumento(row)" class="mhand">
            Visualizar arquivo
          </td>
          <td>
            <i class="fas fa-edit mr-3 btn btn-warning" title="Editar documento" @click="editarItem(row)"></i>
            <i class="fas fa-trash-alt btn btn-danger" title="Deletar documento" @click="deletarItem(row)"></i>
          </td>
        </template>
      </MyTable>
    </div>
  </div>
</template>

<script>
import empresaDocumentosRepository from './../../../services/api/empresaDocumentosRepository'
import MyTable from './../../../uicomponents/MyTable'
import EmpresaUnidadeDocumentosUpload from './EmpresaUnidadeDocumentosUpload.vue'
import EmpresaUnidadeAlterarDocumentoUpload from './EmpresaUnidadeAlterarDocumentoUpload.vue'
export default {
  components: {
    MyTable,
    EmpresaUnidadeDocumentosUpload,
    EmpresaUnidadeAlterarDocumentoUpload
  },
  props: {
    empresaId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      upload: false,
      documento: null,
      documentos: [],
      columns: [
        { label: 'Nome arquivo', field: 'nome_arquivo', sortable: 'nome_arquivo' },
        { label: 'Data inclusão', field: 'created_at', sortable: 'created_at' },
        { label: 'Visualizar', field: '', sortable: '' },
        { label: 'Ações', field: '', sortable: '' },
      ]
    }
  },
  mounted () {
    this.$store.commit("setSplashScreen", false)
    if(this.empresaId > 0) {
      this.listar(this.empresaId)
    }
  },
  methods: {
    listar(idEmpresa) {
      this.$store.commit("setSplashScreen", true)
      empresaDocumentosRepository.listarDocumentosPorEmpresaId(idEmpresa).then(response => {
        this.$store.commit("setSplashScreen", false)
        if(response.data.success) {
          this.documentos = response.data.data
        } else { 
          this.$swal({
            icon: "error",
            text: 'Ocorreu um erro ao listar os documentos!',
          })
        }
      }).catch(error => {
        this.$store.commit("setSplashScreen", false)
        console.log(error.response)
        if(error.response.status != 500) {
          this.$swal({
            icon: "error",
            text: error.response.data.errors[0],
          })
        }        
      })
    },
    fecharModal(refresh = false) {
      this.upload = false
      this.documento = null
      if(refresh) {
        this.listar(this.empresaId)
      }
    },
    obterDocumento(item) {
      this.$store.commit("setSplashScreen", true)
      empresaDocumentosRepository.obterDocumentoPorId(item.id).then(response => {
        this.$store.commit("setSplashScreen", false)
        console.log(response.data.data)
        window.open(response.data.data, '_blank')
      }).catch((error) => {
        console.error(error.response)
        this.$store.commit("setSplashScreen", true)
      })
    },
    deletarItem (item) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover arquivo',
        text: `Deseja deletar o arquivo ${item.nome_arquivo}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarItemConfirmado(item)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Arquivo removido com sucesso!'
          }).then(() => {
            _this.listar(_this.empresaId)
          })
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarItemConfirmado (item) {
      this.$store.commit("setSplashScreen", true)
      empresaDocumentosRepository.deletarDocumento(item.id).then((response) => {
        this.$store.commit("setSplashScreen", false)
        return response.data['success']
      }).catch(() => {
        this.$store.commit("setSplashScreen", false)
        return false
      })
    },
    editarItem(item) {
      this.documento = item
    }
  }
}
</script>

<style>

</style>