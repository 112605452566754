import Repository from './repository'

const resource = '/v1/empresas/unidade-documentos'

export default {
  buscaDocumentoPorId (idDocumento) {
    return Repository.get(`${resource}/${idDocumento}`)
  },
  obterDocumentoPorId (idDocumento) {
    return Repository.get(`${resource}/obter/${idDocumento}`)
  },
  listarDocumentosPorEmpresaId (unidadeId) {
    return Repository.get(`${resource}/unidade/${unidadeId}`)
  },
  salvarDocumento (data) {
    return Repository.post(`${resource}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  atualizarDocumento (idDocumento, data) {
    return Repository.post(`${resource}/${idDocumento}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  deletarDocumento (idDocumento) {
    return Repository.delete(`${resource}/${idDocumento}`)
  },
  gerarPdf(data) {
    return Repository.post(`${resource}/pdf`, data, {responseType: 'blob'})
  }
}
