<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3 v-if="mostraDocumentos">Documentos de {{unidade.nome_fantasia}} </h3>
						<h3 v-else>Cadastro de unidade</h3>
					</div>
				</div>
			</div>
			
			<div class="card-body">
				<div class="row">
					<div class="col-12 mb-3 text-right">
						<span class="font-weight-bold text-dark-color mhand" @click="mostraDocumentos = !mostraDocumentos">{{ mostraDocumentos ? 'Visualizar Dados >>>' : 'Visualizar documentos >>>'}}</span>
					</div>
				</div>
				<EmpresaUnidadeDocumentos v-if="mostraDocumentos" :empresaId="unidade.id" />
				<form @submit.prevent="salvar" v-if="!mostraDocumentos">
					<div class="row" >
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="razaosocial">Razão Social</label>
								<input v-model="unidade.razao_social" type="text" class="form-control" name="razaosocial" id="razaosocial"  placeholder="Razão Social">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group"  :class="{ 'form-group--error': $v.unidade.nome_fantasia.$error }">
								<label for="nomefantasia" class="required">Nome Fantasia</label>
								<input v-model="unidade.nome_fantasia" type="text" class="form-control" name="nomefantasia" id="nomefantasia"  placeholder="Nome Fantasia">
								<div class="error-form text-danger" v-if="!$v.unidade.nome_fantasia.required">Campo obrigatório</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="responsavel">Responsável</label>
								<input v-model="unidade.responsavel" type="text" class="form-control" name="responsavel" id="responsavel"  placeholder="Nome do responsável">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="nit">NIT</label>
								<input v-model="unidade.nit" type="text" class="form-control" name="nit" id="nit"  v-mask="'###.#####.##-#'" placeholder="999.99999.99-9">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.cnpj.$error }">
								<label for="cnpj" class="required" >CNPJ</label>
								<input v-model="cnpj" type="text" class="form-control" name="cnpj" id="cnpj"  placeholder="99.999.999/9999-99" v-mask="'##.###.###/####-##'">
								<div class="error-form text-danger" v-if="!$v.cnpj.required">Campo obrigatório</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="inscricaoestadual">Inscrição Estadual</label>
								<input v-model="unidade.inscricao_estadual" type="text" class="form-control" name="inscricaoestadual" id="inscricaoestadual"  placeholder="Inscrição Estadual">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="graurisco">Grau de Risco</label>
								<input v-model="unidade.grau_risco" type="text" class="form-control" name="graurisco" id="graurisco"  placeholder="Grau de Risco">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="areaatividade">Área de Atividade</label>
								<input v-model="unidade.area_atividade" type="text" class="form-control" name="areaatividade" id="areaatividade"  placeholder="Área de Atividade">
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="telefone" >Telefone</label>
								<input v-model="unidade.telefone" type="tel" class="form-control" name="telefone" id="telefone" placeholder="(99) 99999-9999"
								v-mask="['(##) ####-####', '(##) #####-####']"
								:masked="true">
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="cep">CEP</label>
								<input v-model="cep" type="text" class="form-control" name="cep" id="cep" placeholder="99.999-999" v-mask="'#####-###'">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="logradouro">Logradouro</label>
								<input v-model="unidade.logradouro" type="text" class="form-control" name="logradouro" id="logradouro">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="numero">Número</label>
								<input v-model="unidade.numero" type="text" ref="numero" class="form-control" name="numero" id="numero">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="complemento">Complemento</label>
								<input v-model="unidade.complemento" type="text" class="form-control" name="complemento" id="complemento">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="bairro">Bairro</label>
								<input v-model="unidade.bairro" type="text" class="form-control" name="bairro" id="bairro">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="cidade">Cidade</label>
								<input v-model="unidade.cidade" type="text" class="form-control" name="cidade" id="cidade">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="estado">Estado</label>
								<input v-model="unidade.estado" type="text" class="form-control" name="estado" id="estado" v-mask="'AA'">
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</form>
			</div>
		</div>
  </div>
</template>

<script>
import empresaRepository from './../../services/api/empresaRepository'
import {GetAddress} from './../../services/cep.service'
import { required } from 'vuelidate/lib/validators'
import { validarCNPJ } from './../../services/cnpj.service'
import EmpresaUnidadeDocumentos from './documentos/EmpresaUnidadeDocumentos.vue'
export default {
	components: {
		EmpresaUnidadeDocumentos
	},
	validations: {
    unidade: {
			nome_fantasia: {
				required
			},
			cnpj: {required}
		},
		cnpj: {required}
  },
	data () {
		return {
			mostraDocumentos: false,
			unidade: {
				id: 0,
				nome_fantasia: null,
				cnpj: null,
			},
			cep: null,
			cnpj: null
		}
	},
	watch: {
    cep: {
      handler () {
        if (this.cep && this.cep !== null && this.cep.length == 9) {
					this.buscaCep()
				}
      }
		},
		cnpj: {
      handler () {
        if (this.cnpj && this.cnpj !== null && this.cnpj.length == 18) {
					this.verificaCNPJ()
				}
      }
    }
	},
	created () {
		this.$store.commit("setSplashScreen", false)
		if (this.$route.query.id) {
			this.buscaUnidade(this.$route.query.id)
		} else if (this.$route.query.idEmpresa) {
			this.unidade.empresa_id = this.$route.query.idEmpresa
		}
	},
	methods: {
		verificaCNPJ() {
			let cnpj = this.cnpj
			cnpj = cnpj.replace(/\./g,'').replace('-','')
			let check = validarCNPJ(cnpj)
			if (!check) {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'CNPJ inválido!',
				}).then ( () => {
					this.cnpj = null
				})
			}
			return check
		},
		buscaUnidade (idEmpresa) {
			empresaRepository.buscaEmpresaUnidade(idEmpresa).then(response => {
				this.unidade = response.data['data']
				this.cep = this.unidade.cep
				this.cnpj = this.unidade.cnpj
			})
		},
		buscaCep: function () {
			GetAddress(this.cep).then(endereco => {
				this.unidade.cep = this.cep
				this.unidade.logradouro = endereco.logradouro
				this.unidade.bairro = endereco.bairro
				this.unidade.cidade = endereco.cidade
				this.unidade.estado = endereco.estado
				this.$refs.numero.focus()
				// resolve, mas nao deveria precisar
				this.$forceUpdate()
			})
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Empresas' })
    },
		salvar () {
			this.unidade.cnpj = this.cnpj
			this.unidade.cep = this.cep ? this.cep : ''

			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.unidade.id && this.unidade.id != 0) {
					empresaRepository.atualizarEmpresaUnidade(this.unidade.id, this.unidade).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					})
				} else {
					let unidade = Object.assign({}, this.unidade)
					delete unidade.id
					empresaRepository.salvarEmpresaUnidade(unidade).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Unidade cadastrada com sucesso!'
							}).then( () => {
								this.$router.go()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							})
						}
					})
				}
			}
		}
	}
}
</script>

<style>

</style>