<template>
  <transition name="fade">
    <div class="modal-mask animated">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Alteração de documento</h4>
            <button type="button" class="close" data-dismiss="modal" @click="$emit('fechar')">&times;</button>
          </div>
          <div class="modal-body">
            <!-- <div class="row">
              <div class="col text-right">
                <button type="button" class="btn btn-outline-info p-1" style="font-size: small;" @click="addDocumento()">Adicionar documento</button>
              </div>
            </div> -->
            <div class="row">
              <div class="col-12 col-md-5 pr-0">
                <div class="form-group">
                  <input type="text" v-model="item.nomeDocumento" class="form-control" placeholder="Nome do documento">
                </div>
              </div>
              <div class="col-12 col-md-7">
                <label class="align-items-center custom-file d-flex" v-if="!item.file">
                  <input type="file"  style="display:none;" id="attachments" @change="attach($event)" name="anexararquivo" placeholder="Anexar arquivos" class="custom-file-input">
                  <span class="custom-file-control btn btn-sm btn-info btn-fill btn-wd"  >Anexar arquivo</span>
                </label>
                <label class="align-items-center custom-file d-flex" v-if="item.file">
                  <span style="cursor: pointer;" @click="removeAttachment()"><a class="btn btn-danger btn-sm text-white">Remover</a></span>
                  <span class="label label-primary text-small">{{ ' (' + Number((item.file.size / 1024).toFixed(1)) + 'Kb)'}}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-around modal-footer">
            <button type="submit" @click="salvar" class="btn btn-sm btn-primary float-right">ENVIAR ARQUIVOS</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import empresaDocumentosRepository from '../../../services/api/empresaDocumentosRepository'
export default {
  props: {
    documento: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      item: {
        nomeDocumento: '',
        file: null
      }
    }
  },
  mounted() {
    console.log(this.documento)
    this.item.nomeDocumento = this.documento.nome_arquivo
  },
  methods: {
    attach: function (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      for (var i = files.length - 1; i >= 0; i--) {
        this.item.file = files[i]
      }
      document.getElementById('attachments').value = []
    },
    removeAttachment () {
      this.item.file = null
    },
    salvar() {
      const formDataitems = new FormData()
      formDataitems.append('documentoId', this.documento.id)
      formDataitems.append('unidadeId', this.documento.empresa_unidade_id)
      formDataitems.append('nomeDocumento', this.item.nomeDocumento)
      if(this.item.file) {
        formDataitems.append(`files[0]`, this.item.file, this.item.nomeDocumento)
      }
			this.$store.commit("setSplashScreen", true)
      empresaDocumentosRepository.atualizarDocumento(this.documento.id, formDataitems).then(response => {
        this.$store.commit("setSplashScreen", false)
        console.log(response.data.data)
        this.$emit('fechar', true)
      }).catch( (error) => {
        this.$store.commit("setSplashScreen", false)
        console.log(error)
      })
    }
  }
}
</script>

<style>

</style>